import React from 'react';
import IconBase from './base';

interface IMailProps {
	className?: string;
}

const MailIcon: React.FC<IMailProps> = ({ className = '' }) => {
	return (
		<IconBase width="22.138" height="17.018" viewBox="0 0 22.138 17.018" className={className}>
			<g transform="translate(-930.931 -424.968)">
				<rect
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					width="21.138"
					height="16.018"
					rx="2.485"
					transform="translate(931.431 425.468)"
				/>
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					d="M931.257 428.78L942 436.211l10.743-7.431M931.513 437.863l6.605-4.294M952.485 437.863l-6.605-4.294"
				/>
			</g>
		</IconBase>
	);
};

export default MailIcon;

import React from 'react';
import IconBase from './base';

interface IGridViewProps {
	isActive?: boolean;
	className?: string;
}

const GridViewIcon: React.FC<IGridViewProps> = ({ isActive = false, className = '' }) => {
	const fill = isActive ? 'currentColor' : 'transparent';
	const stroke = isActive ? '#fff' : '#000';

	return (
		<IconBase width="29" height="29" viewBox="0 0 29 29" className={`text-brand ${className}`}>
			<g>
				<g transform="translate(.5 .5)">
					<circle
						cx="14"
						cy="14"
						r="14"
						fill={fill}
						stroke={stroke}
						strokeLinecap="round"
					/>
				</g>
				<g fill={stroke}>
					<g transform="translate(9.5 8.833)">
						<circle cx="1.04" cy="1.04" r="1.04" />
						<circle cx="1.04" cy="1.04" r="1.04" transform="translate(4.096)" />
						<circle cx="1.04" cy="1.04" r="1.04" transform="translate(8.192)" />
					</g>
					<g transform="translate(9.5 12.961)">
						<circle cx="1.04" cy="1.04" r="1.04" />
						<circle cx="1.04" cy="1.04" r="1.04" transform="translate(4.096)" />
						<circle cx="1.04" cy="1.04" r="1.04" transform="translate(8.192)" />
					</g>
					<g transform="translate(9.5 17.088)">
						<circle cx="1.04" cy="1.04" r="1.04" />
						<circle cx="1.04" cy="1.04" r="1.04" transform="translate(4.096)" />
						<circle cx="1.04" cy="1.04" r="1.04" transform="translate(8.192)" />
					</g>
				</g>
			</g>
		</IconBase>
	);
};

export default GridViewIcon;

export const enum PAGE_CATEGORY {
	HOMEPAGE = 'homepage',
	PRODUCT_SELECTOR = 'product selector page',
	PRODUCT_DETAIL = 'product detail page',
	CHECKOUT = 'checkout page',
	APPENDIX = 'appendix',
	CONTENT_BRANDING = 'content page:: branding page',
	CONTENT_ARTICLE = 'content page:: article',
	ACCOUNT_MANAGEMENT = 'my account',
	INTERNAL_SEARCH = 'internal search',
	ERROR = 'error page',
}

export const enum CUSTOM_KEYS {
	PRODUCT_SIZE = 'dimension35',
	PRODUCT_SHADE = 'dimension36',
	PRODUCT_FRAGRANCE = 'dimension37',
	PRODUCT_RATING = 'dimension38',
	PRODUCT_STOCK_STATUS = 'dimension39', // "in stock" | "out of stock"
	PRODUCT_REPLENISHMENT = 'dimension40', // number
	PRODUCT_VARIATION_ID = 'dimension48',
	PRODUCT_VIRTUAL_TRY_ON = 'dimension49',
	PRODUCT_ORIGINAL_PRICE = 'dimension50',
	PRODUCT_REVIEW_COUNT = 'dimension59',
	PRODUCT_FINDER_REFERRER = 'dimension91', // valid values are "skindr" | "teleconsultation" | "none"
}

// Dependencies
import React, { useContext, useMemo } from 'react';
import useSWR from 'swr';
import dynamic from 'next/dynamic';
// Workspaces
import { ResizeContext } from 'utils/context';
import useUser from 'utils/useUser';
import { useProfile } from 'utils/useProfile';

const DynamicBasketPopover = dynamic(() => import('../BasketPopover'));
const DesktopNav = dynamic(() => import('./DesktopNav'), {
	ssr: false,
});
const MobileNav = dynamic(() => import('./MobileNav'), {
	ssr: false,
});
const AdminDialog = dynamic(() => import('../AdminDialog'), { ssr: false });

interface IHeaderProps {}

const Header: React.FC<IHeaderProps> = () => {
	const { isLoading, user } = useProfile();
	const { size } = useContext(ResizeContext);

	const nav = useMemo(() => {
		if (size === 'large') {
			return (
				<DesktopNav
					user={user}
					isLoading={isLoading}
					basketSlot={<DynamicBasketPopover />}
				/>
			);
		}

		return (
			<MobileNav user={user} isLoading={isLoading} basketSlot={<DynamicBasketPopover />} />
		);
	}, [size, user, isLoading]);

	return (
		<>
			{user?.actingAsUser && <AdminDialog />}
			<header className="w-full">{nav}</header>
		</>
	);
};

export default Header;

export enum SITE_IDS {
	SHOP = '2',
	PROSHOP = '1',
}

export enum LOCALE {
	AU = 'au',
	NZ = 'nz',
}

export enum BROWSER_STORAGE_KEYS {
	PRODUCT_PAGE_ACTIVE_VIEW = 'b2bProductPageActiveView',
	PRODUCT_PAGE_SORT_MODE = 'b2bProductPageSortMode',
	ADMIN_PANEL_EXPANDED = 'B2B.adminPanelExpanded',
	USER_ID = 'b2b-user-id',
}

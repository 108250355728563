import React from 'react';
import IconBase from './base';

interface ISwapIconProps {
	className?: string;
}

const SwapIcon: React.FC<ISwapIconProps> = ({ className = '' }) => {
	return (
		<IconBase
			width="29.273"
			height="22.402"
			viewBox="0 0 29.273 22.402"
			className={`text-black ${className}`}
		>
			<g fill="none" stroke="currentColor" strokeLinecap="round">
				<g>
					<path d="M4.075 7.978a11.121 11.121 0 0 1 21.628 3.641" />
					<path d="m28.566 9.087-2.989 2.989-2.989-2.989" />
				</g>
				<g>
					<path d="M25.114 14.656A11.121 11.121 0 0 1 3.57 10.778" />
					<path d="m.704 13.315 2.989-2.989 2.989 2.989" />
				</g>
			</g>
		</IconBase>
	);
};

export default SwapIcon;

import React from 'react';
import IconBase from './base';

interface IBestSellerProps {
	className?: string;
}

const BestSellerIcon: React.FC<IBestSellerProps> = ({ className = '' }) => {
	return (
		<IconBase width="107.561" height="22" viewBox="0 0 107.561 22" className={className}>
			<path fill="#d8bf86" d="M0 0h102v22H0zM102 0h5.561L102 11zM102 22h5.561L102 11z" />
			<text
				className="font-bold uppercase leading-none"
				transform="translate(22 5)"
				fontSize="10"
				letterSpacing=".05em"
				fill="#fff"
			>
				<tspan x="0" y="10">
					BEST SELLER
				</tspan>
			</text>
			<path
				d="M16.017 7.996a2.633 2.633 0 00-1.959-.853 2.465 2.465 0 00-1.539.531.623.623 0 11-1.244 0 2.462 2.462 0 00-1.539-.531 2.634 2.634 0 00-1.959.853A3.061 3.061 0 007 10.087a3.645 3.645 0 00.971 2.386 20.692 20.692 0 002.432 2.282c.337.287.719.613 1.115.96a.576.576 0 00.757 0c.4-.347.779-.672 1.115-.96a20.716 20.716 0 002.432-2.282 3.647 3.647 0 00.971-2.386 3.06 3.06 0 00-.777-2.091zm0 0"
				fill="#fff"
			/>
		</IconBase>
	);
};

export default BestSellerIcon;

import React from 'react';
import IconBase from './base';

interface IChevronLeftProps {
	className?: string;
}

const ChevronLeftIcon: React.FC<IChevronLeftProps> = ({ className = '' }) => {
	return (
		<IconBase width="9.8" height="16.758" viewBox="0 0 9.8 16.758" className={className}>
			<g>
				<path
					d="M9.074.707l-7.66 7.66 7.66 7.66"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
				/>
			</g>
		</IconBase>
	);
};

export default ChevronLeftIcon;

import { createContext } from 'react';

export type ScreenSizes = 'xsmall' | 'small' | 'medium' | 'large' | null;

export const AccountSwitcherContext = createContext<() => void>(() => {});
export const ResizeContext = createContext<{
	width: number;
	size: ScreenSizes;
}>({
	width: 0,
	size: 'xsmall',
});

import React from 'react';
import IconBase from './base';

interface ICheckProps {
	className?: string;
}

const CheckIcon: React.FC<ICheckProps> = ({ className = '' }) => {
	return (
		<IconBase width="7" height="5.6385" viewBox={`0 0 7 5.6385`} className={`${className}`}>
			<path
				d="M5.92 0L2.426 3.493 1.068 2.135 0 3.203l2.426 2.426 4.562-4.561zm0 0"
				fill="currentColor"
			/>
		</IconBase>
	);
};

export default CheckIcon;

import { captureMessage } from '@sentry/nextjs';
import { useEffect, useState } from 'react';
import { useProfile } from 'utils/useProfile';

/**
 * Keeps an eye on the current user's customer value.
 *
 * If the user changes their customer on another tab, reload this page to get
 * the freshest data.
 */
export const SessionSync = () => {
	const { user } = useProfile();
	const [customer, setCustomer] = useState(user?.customer);

	/**
	 * Once SWR loads the current customer, store it for later comparison
	 */
	useEffect(() => {
		if (user?.customer) {
			setCustomer(user.customer);
		}
	}, [user?.customer]);

	/**
	 * If we are the newest tab, broadcast our customer to other tabs
	 */
	useEffect(() => {
		const channel = new BroadcastChannel('current-customer');

		if (user?.customer) {
			channel.postMessage(user.customer);
		}

		return () => {
			channel.close();
		};
	}, [user?.customer]);

	/**
	 * If we are not the newest tab, listen for changes to the customer
	 * from other tabs
	 */
	useEffect(() => {
		const channel = new BroadcastChannel('current-customer');

		channel.onmessage = event => {
			const latestCustomer = event.data;

			if (!latestCustomer || !customer) {
				return;
			}

			if (latestCustomer?.customerCode !== customer?.customerCode) {
				captureMessage('Customer changed', {
					extra: {
						customerBefore: customer,
						customerAfter: latestCustomer,
					},
				});

				window.location.reload();
			}
		};

		return () => {
			channel.close();
		};
	}, [customer]);

	return null;
};

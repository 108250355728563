import { createContext, Dispatch, SetStateAction, useCallback, useContext, useMemo } from 'react';
import { IBasket, IBasketProduct, IProduct } from '@b2b-frontend/types';

type ProductMaterialId = string;
export type BasketItemStatuses = null | 'adding' | 'removing';

export interface IUseBasketProps {
	itemState: Record<string, BasketItemStatuses>;
	setItemState: Dispatch<SetStateAction<Record<string, BasketItemStatuses>>>;
	addToBasket: ({ material, quantity }: { material: string; quantity: string }) => void;
	error: null | string;
	isBasketLoading: boolean;
	updateQuantity: ({ material, quantity }: { material: string; quantity: string }) => any;
	removeItemFromBasket: (material: string) => void;
	itemMap: Record<ProductMaterialId, IBasketProduct>;
	basket: IBasket | null;
	fetchBasket: () => void;
}

interface IUseBasket extends IUseBasketProps {
	isInBasket: (materialId?: string) => boolean;
	upsertItem: ({ material, quantity }: { material: string; quantity: string }) => void;
	items: Map<ProductMaterialId, IBasketProduct>;
}

export const BasketContext = createContext<IUseBasketProps | null>(null);

const useBasket = (): IUseBasket => {
	const base = useContext(BasketContext);

	if (!base) {
		throw new Error('useBasket must be used within a BasketProvider');
	}

	const isInBasket = useCallback(
		(materialId?: string): boolean => {
			if (!materialId) {
				return false;
			}

			return Boolean(base.itemMap[materialId]);
		},
		[base.itemMap],
	);

	const { updateQuantity, addToBasket } = base;

	const upsertItem = useCallback(
		({
			material,
			quantity,
			...otherProductAttributes
		}: {
			material: string;
			quantity: string;
			[key: string]: any;
		}) => {
			if (isInBasket(material)) {
				return updateQuantity({ material, quantity, ...otherProductAttributes });
			} else {
				return addToBasket({ material, quantity, ...otherProductAttributes });
			}
		},
		[isInBasket, updateQuantity, addToBasket],
	);

	const items = useMemo(() => {
		return new Map(
			Object.entries(base.itemMap).map(([materialId, product]) => [materialId, product]),
		);
	}, [base.itemMap]);

	return {
		...base,
		isInBasket,
		upsertItem,
		items,
	};
};

export default useBasket;

import useSWR from 'swr';
import useUser from './useUser';

export const useProfile = () => {
	const { isLoggedIn } = useUser();
	const { data: user = [], isValidating: isLoading } = useSWR(() =>
		isLoggedIn ? '/api/user' : null,
	);

	return {
		user,
		isLoading,
	};
};

import React from 'react';
import IconBase from './base';

interface IOutOfStockIconProps {
	className?: string;
}

const OutOfStockIcon: React.FC<IOutOfStockIconProps> = ({ className = '' }) => {
	return (
		<IconBase className={className} width="107.561" height="22" viewBox="0 0 107.561 22">
			<rect width="102" height="22" fill="#727779" />
			<path
				d="M2779.275,297h5.561l-5.561,11Z"
				transform="translate(-2677.275 -297)"
				fill="#727779"
			/>
			<path
				d="M2779.275,308h5.561l-5.561-11Z"
				transform="translate(-2677.275 -286)"
				fill="#727779"
			/>
			<text
				className="font-bold uppercase leading-none"
				transform="translate(9 5)"
				fontSize="10"
				letterSpacing=".05em"
				fill="currentColor"
			>
				<tspan x="0" y="10">
					OUT OF STOCK
				</tspan>
			</text>
		</IconBase>
	);
};

export default OutOfStockIcon;

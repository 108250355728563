import axios from 'axios';
import { nanoid } from 'nanoid';
import { APIError } from './errors';
import { setStorageItem } from './storage';
import { HTTP_RESPONSE_ON_PROFILE_FETCH_ERROR } from './auth0Extras';

export const returnKeyIfParamsAreDefined = (key: string | string[], params: unknown[]) => {
	for (const param of params) {
		if (!param) {
			return null;
		}
	}

	return key;
};

const fetcher = (url: string) => {
	const isCustomerRequest = url === '/api/user';
	const isProfileFetch = url.includes('/api/auth/me');

	return axios
		.get(url, {
			headers: {
				'trace-id': nanoid(),
			},
		})
		.then(res => {
			if (isProfileFetch) {
				if (res.status === HTTP_RESPONSE_ON_PROFILE_FETCH_ERROR) {
					throw new Error('Session expired');
				}
			}

			if (res.data.pagination) {
				return [res.data.data, res.data.pagination];
			}

			let data;

			if (typeof res.data.data !== 'undefined') {
				data = res.data.data;
			} else {
				data = res.data;
			}

			if (isCustomerRequest && data) {
				// When a new user is created (via Auth0 or a L'Oreal staff member using SSO),
				// they may not have a customer assigned to their account. Similarly, admin accounts
				// e.g. KO internal staff will never have a customer assigned unless they impersonate
				// via the admin dashboard. In these cases, we redirect the user to the onboarding page
				// which prompts them to submit a contact enquiry.
				if (!data?.customer?.customerCode && window.location.pathname !== '/onboarding') {
					window.location.href = '/onboarding';
				}
			}

			return data;
		})
		.catch(err => {
			throw new APIError(err.message, err?.response?.data || {});
		});
};

export default fetcher;

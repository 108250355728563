import React from 'react';
import IconBase from './base';

interface ICameraIconProps {
	className?: string;
	width?: number;
}

const CameraIcon: React.FC<ICameraIconProps> = ({ className = '' }) => {
	return (
		<IconBase width="64.204" height="53.93" viewBox="0 0 64.204 53.93" className={className}>
			<path
				fill="none"
				stroke="#dbdbdb"
				strokeMiterlimit="10"
				d="M1092.094,352.854h-8.353a3.924,3.924,0,0,1-2.792-1.157l-4.816-4.813a6.536,6.536,0,0,0-4.657-1.931h-11.437a6.535,6.535,0,0,0-4.655,1.931l-4.816,4.813a3.925,3.925,0,0,1-2.795,1.157h-8.351a5.273,5.273,0,0,0-5.268,5.268v34.235a5.273,5.273,0,0,0,5.268,5.268h52.672a5.272,5.272,0,0,0,5.266-5.268V358.122a5.272,5.272,0,0,0-5.266-5.268Zm-26.336,36.869a15.8,15.8,0,1,1,15.8-15.8,15.818,15.818,0,0,1-15.8,15.8Zm21.068-23.7a2.634,2.634,0,1,1,2.634-2.634,2.637,2.637,0,0,1-2.634,2.634Zm0,0"
				transform="translate(-1033.654 -344.453)"
			/>
		</IconBase>
	);
};

export default CameraIcon;

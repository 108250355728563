import { createContext, useContext } from 'react';

export interface ViewModifierContextType extends Record<string, unknown> {
	hidePrices: boolean;
	allowAddToCart?: boolean;
}

export const defaultViewModifierValue: ViewModifierContextType = {
	hidePrices: false,
	allowAddToCart: true,
};

export const ViewModifierContext = createContext<ViewModifierContextType>(defaultViewModifierValue);

export const useViewModifier = (): ViewModifierContextType => {
	const context = useContext(ViewModifierContext);

	if (!context) {
		throw new Error('useViewModifier must be used within a ViewModifierProvider');
	}

	return context;
};

export const ViewModifier: React.FC<
	React.PropsWithChildren<{ value: Partial<ViewModifierContextType> }>
> = ({ children, value }) => {
	const upstreamValue = useViewModifier();

	return (
		<ViewModifierContext.Provider value={{ ...upstreamValue, ...value }}>
			{children}
		</ViewModifierContext.Provider>
	);
};

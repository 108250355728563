import React from 'react';
import IconBase from './base';

interface IPhoneProps {
	className?: string;
	width?: number;
	height?: number;
}

const Phone: React.FC<IPhoneProps> = ({ className = '', width = 21.917, height = 24.055 }) => {
	return (
		<IconBase
			width={`${width}px`}
			height={`${height}px`}
			viewBox={`0 0 ${width} ${height}`}
			className={className}
		>
			<path
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeLinecap="round"
				d="M11.575 3.648a7 7 0 016.659 7.333"
			/>
			<path
				d="M18.047 18.852l-2.345-3.047a1.809 1.809 0 00-3.073.285 1.85 1.85 0 01-2.268.88 8.383 8.383 0 01-4.355-5.659 1.759 1.759 0 011.432-1.967 1.808 1.808 0 001.062-2.9L6.156 3.397a1.94 1.94 0 00-2.6-.339L1.488 4.649c-2.08 1.688-.722 7.054 3.213 12.168s8.763 7.9 10.939 6.223l2.068-1.591a1.94 1.94 0 00.339-2.597z"
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
			/>
			<path
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				strokeLinecap="round"
				d="M10.948.549c5.194-.517 9.855 3.6 10.413 9.2a10.861 10.861 0 01-.222 3.535"
			/>
		</IconBase>
	);
};

export default Phone;

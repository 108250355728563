/**
 * @param address something like "DANDENONG SOUTH, Victoria, 3175, Australia"
 */
export const parseBasicAddress = (address: string) => {
	const bits = address.split(',').reverse();

	return {
		country: bits[0]?.trim(),
		postcode: bits[1]?.trim(),
		state: bits[2]?.trim(),
		city: bits[3]?.trim(),
	};
};

import React from 'react';
import IconBase from './base';

interface ICrossIconProps {
	width?: number;
	className?: string;
}

const CrossIcon: React.FC<ICrossIconProps> = ({ width = 12.414 }) => {
	return (
		<IconBase width={`${width}`} height={`${width}`} viewBox={`0 0 ${width} ${width}`}>
			<g transform="translate(0.707 0.707)">
				<line
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					className="a"
					x2="11"
					y2="11"
				/>
				<line
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					className="a"
					y1="11"
					x2="11"
				/>
			</g>
		</IconBase>
	);
};

export default CrossIcon;

import React from 'react';
import IconBase from './base';

interface IListViewProps {
	isActive?: boolean;
	className?: string;
}

const ListViewIcon: React.FC<IListViewProps> = ({ isActive = false, className = '' }) => {
	const fill = isActive ? 'currentColor' : 'transparent';
	const stroke = isActive ? '#fff' : '#000';

	return (
		<IconBase width="29" height="29" viewBox="0 0 29 29" className={`text-brand ${className}`}>
			<g>
				<g transform="translate(.5 .5)">
					<circle
						cx="14"
						cy="14"
						r="14"
						fill={fill}
						stroke={stroke}
						strokeLinecap="round"
					/>
				</g>
				<g fill="none" stroke={stroke} strokeLinecap="round">
					<path d="M8.81 10.731h11.38" />
					<path d="M8.81 14.5h11.38" />
					<path d="M8.81 18.269h11.38" />
				</g>
			</g>
		</IconBase>
	);
};

export default ListViewIcon;

import React from 'react';
import IconBase from './base';

interface IGiftIconProps {
	className?: string;
}

const GiftIcon: React.FC<IGiftIconProps> = ({ className = '' }) => {
	return (
		<IconBase width="24.301" height="26.625" viewBox="0 0 24.301 26.625" className={className}>
			<g fill="none" stroke="currentColor">
				<g>
					<path d="M19.986 14.4v9.725a2 2 0 0 1-2 2H2.5a2 2 0 0 1-2-2V12.584h14.884" />
					<path d="M8.537 12.584h3.413V26.07H8.537z" />
				</g>
				<g>
					<g>
						<path d="m5.64 3.488 15.953 5.874a2.2 2.2 0 0 1 1.304 2.824l-1.037 2.816L1.778 7.608l1.037-2.815A2.2 2.2 0 0 1 5.64 3.488Z" />
						<path d="m11.872 5.776 3.522 1.297-1.792 4.865-3.521-1.296z" />
					</g>
					<g>
						<path d="M12.566 1.39c.764.994 1.329 4.187.967 4.612-.316.372-3.8-.851-4.567-1.845a2.27 2.27 0 1 1 3.6-2.767Z" />
						<path d="M17.682 3.274c-1.226.261-3.726 2.326-3.726 2.884 0 .488 3.446 1.818 4.673 1.556a2.27 2.27 0 1 0-.947-4.44Z" />
					</g>
				</g>
			</g>
		</IconBase>
	);
};

export default GiftIcon;

import React from 'react';
import IconBase from './base';

interface ITrashProps {
	className?: string;
	width?: number;
	height?: number;
}

const Trash: React.FC<ITrashProps> = ({ className = '' }) => {
	return (
		<IconBase
			width="18.648px"
			height="23.445px"
			viewBox="0 0 18.648 23.445"
			className={className}
		>
			<path
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				d="M10.021 4.523h-8.05L3.259 21.56a1.5 1.5 0 001.494 1.385h9.142a1.5 1.5 0 001.494-1.385l1.288-17.037zM1.535 2.201h15.579a1.035 1.035 0 011.035 1.035v1.287H.5V3.236a1.035 1.035 0 011.035-1.035z"
			/>
			<path fill="none" stroke="currentColor" strokeMiterlimit="10" d="M3.209 19.025h12.23" />
			<path
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeMiterlimit="10"
				d="M6.378 18.871L5.707 7.157M12.27 18.871l.671-11.714M9.324 18.716V7.105"
			/>
			<path
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
				d="M8.599.5h1.448a1.7 1.7 0 011.7 1.7H6.899a1.7 1.7 0 011.7-1.7z"
			/>
		</IconBase>
	);
};

export default Trash;

import React from 'react';
import IconBase from './base';

interface IUserProps {
	className?: string;
}

const User: React.FC<IUserProps> = ({ className = '' }) => {
	return (
		<IconBase width="16.933" height="23.129" viewBox="0 0 16.933 23.129" className={className}>
			<g transform="translate(-1004.486 -344.887)">
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					d="M1016.95 361.3l-4 4-4-4a6.861 6.861 0 00-3.969 6.219h15.933a6.861 6.861 0 00-3.964-6.219zM1017.668 347.173a9.777 9.777 0 01-11.8 4.452 7.145 7.145 0 1011.8-4.452z"
				/>
				<circle
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					cx="7.148"
					cy="7.148"
					r="7.148"
					transform="translate(1005.804 345.387)"
				/>
			</g>
		</IconBase>
	);
};

export default User;

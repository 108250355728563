import React from 'react';
import clsx from 'clsx';

export interface IHeadingProps extends React.PropsWithChildren {
	/**
	 * @deprecated
	 */
	Tag?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	as?: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
	size?:
		| 'text-2xs'
		| 'text-xs'
		| 'text-sm'
		| 'text-base'
		| 'text-md'
		| 'text-lg'
		| 'text-2xl'
		| string;
	serif?: boolean;
	className?: string;
}

const Heading: React.FC<IHeadingProps> = ({
	className = '',
	children,
	size = 'text-lg',
	serif = true,
	as = 'h1',
	...props
}) => {
	// TODO: remove this once all of the places using this use as instead of Tag
	const Tag = props.Tag ? props.Tag : as;

	return (
		<Tag
			className={clsx('font-bold', className, size, {
				'font-serif': serif,
				'font-sans': !serif,
			})}
		>
			{children}
		</Tag>
	);
};

export default Heading;

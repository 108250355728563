import React from 'react';
import IconBase from './base';

interface ICautionProps {
	className?: string;
	width?: number;
	height?: number;
}

const CautionIcon: React.FC<ICautionProps> = ({
	className = '',
	width = 25.046,
	height = 23.451,
}) => {
	return (
		<span className={`text-white ${className}`}>
			<IconBase
				width={`${width}px`}
				height={`${height}px`}
				viewBox={`0 0 ${width} ${height}`}
			>
				<path
					className="a"
					fill="none"
					stroke="#d7be89"
					strokeLinecap="round"
					strokeMiterlimit="10"
					d="M11.429 1.153L.666 21.119a1.242 1.242 0 001.093 1.832h21.528a1.242 1.242 0 001.093-1.832L13.616 1.153a1.242 1.242 0 00-2.187 0zM12.523 7.368v7.238M12.523 17.962v1.342"
				/>
			</IconBase>
		</span>
	);
};

export default CautionIcon;

import React from 'react';
import clsx from 'clsx';

interface ISkeletonRowProps {
	width: number | string;
	height?: string;
	className?: string;
	color?: `bg-${string}`;
	rounded?: boolean;
	as?: keyof JSX.IntrinsicElements;
}

const SkeletonRow: React.FC<ISkeletonRowProps> = ({
	width,
	height = 'h-4',
	className = '',
	color = 'bg-gray-light',
	rounded = true,
	as: Tag = 'div',
}) => {
	return (
		<Tag
			className={clsx('animate-pulse', height, className, color, {
				'rounded-full': rounded,
			})}
			style={{ width }}
		/>
	);
};

export default SkeletonRow;

import React from 'react';
import IconBase from './base';

interface IBasketProps {
	className?: string;
}

const BasketIcon: React.FC<IBasketProps> = ({ className = '' }) => {
	return (
		<IconBase className={`${className}`} width="25.49" height="23.21" viewBox="0 0 25.49 23.21">
			<g fill="none" stroke="currentColor" strokeMiterlimit="10">
				<path
					d="M18.176 8.537H2.306a1.825 1.825 0 00-1.768 2.278l2.593 10.139a2.538 2.538 0 002.521 1.785H19.84a2.537 2.537 0 002.521-1.785l2.594-10.139a1.826 1.826 0 00-1.769-2.278z"
					strokeWidth=".942"
				/>
				<path d="M6.965 6.28a5.78 5.78 0 1111.56 0" strokeLinecap="round" />
			</g>
		</IconBase>
	);
};

export default BasketIcon;

import React from 'react';
import IconBase from './base';

interface IDownloadProps {
	className?: string;
	width?: number;
}

const DownloadIcon: React.FC<IDownloadProps> = ({ className = '', width = 11 }) => {
	const aspectRatio = 0.926;
	const height = width * aspectRatio;

	return (
		<span className={`${className}`}>
			<IconBase width={`${width}`} height={`${height}`} viewBox={`0 0 ${width} ${height}`}>
				<path
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M7.948 5.113L5.53 7.531 3.112 5.113M5.53 7.531V.5"
				/>
				<path
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeLinejoin="round"
					d="M10.561 7.231v1.4A1.111 1.111 0 019.45 9.742H1.611A1.11 1.11 0 01.5 8.631v-1.4"
				/>
			</IconBase>
		</span>
	);
};

export default DownloadIcon;

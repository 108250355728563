import clsx from 'clsx';
import type { FC } from 'react';
import useSWR from 'swr';
import Link from '@b2b-frontend/components/Link';
import Logo from '@b2b-frontend/components/icons/Logo';
import SkeletonRow from '@b2b-frontend/components/SkeletonRow';
import { returnKeyIfParamsAreDefined } from '@b2b-frontend/utils/fetcher';
import { useLocale } from '@b2b-frontend/utils/sites';
import { LOCALE } from '@b2b-frontend/constants';
import { ROUTES } from '../../constants';
import Heading from '../Heading';
import MaxWidthContainer from '../MaxWidthContainer';
import useUser from 'utils/useUser';

const clickEventName = 'click_footer_nav';

const Footer: FC = () => {
	const { user, isInitialised } = useUser();
	const locale = useLocale();
	const { data: brands = [], isValidating } = useSWR(() =>
		returnKeyIfParamsAreDefined(`/api/${user ? '' : 'public/'}brands`, [isInitialised]),
	);

	return (
		<footer className="bg-black w-full h-full">
			<MaxWidthContainer className="grid gap-4 footer-layout md:footer-layout-lg py-10 md:py-16 font-sans">
				<div className="grid-area-a">
					<Logo className="md:mx-0 mb-6 md:mb-20 text-white" />
				</div>
				<div className="grid-area-b text-3xs text-gray-medium">
					<p>
						&copy; L&rsquo;Or&eacute;al{' '}
						{locale === LOCALE.AU ? 'Australia' : 'New Zealand'} Pty Ltd
					</p>
					<p>*Recommended Retail Price only.</p>
					<p>You are not required to sell to your customers at this price.</p>
					<p>
						You set your own retail prices. All our prices to you are in{' '}
						{locale === LOCALE.AU ? 'AUD' : 'NZD'}, and ex-GST, unless otherwise stated.
					</p>
				</div>
				<div className="grid-area-c flex flex-wrap justify-start xl:justify-end text-white text-xs mb-6 md:mb-0">
					<div>
						<Heading
							as="h2"
							size="text-xs"
							className="font-sans font-bold uppercase mb-4 mr-4"
						>
							Brands
						</Heading>
						<ul
							className={clsx('text-white space-y-4', {
								'columns-1': brands.length <= 5,
								'columns-2': brands.length > 5 && brands.length <= 10,
								'columns-2 sm:columns-3':
									brands.length > 10 && brands.length <= 100,
							})}
						>
							{isValidating && brands.length === 0 && (
								<>
									<li className="mr-4">
										<SkeletonRow width={110} />
									</li>
									<li className="mr-4">
										<SkeletonRow width={90} />
									</li>
									<li className="mr-4">
										<SkeletonRow width={140} />
									</li>
									<li className="mr-4">
										<SkeletonRow width={100} />
									</li>
									<li className="mr-4">
										<SkeletonRow width={65} />
									</li>
								</>
							)}
							{brands.map(brand => {
								return (
									<li key={brand.brand_slug} className="mr-4">
										<Link
											href={`/brands/${brand.brand_slug}`}
											clickEventName={clickEventName}
										>
											{brand.brand}
										</Link>
									</li>
								);
							})}
						</ul>
					</div>
					<div data-testid="footer_navigation">
						<Heading
							as="h2"
							size="text-xs"
							className="font-sans font-bold uppercase mb-4"
						>
							<Link href={ROUTES.HELP.ROOT}>Let us help you</Link>
						</Heading>
						<ul className="space-y-4">
							<li>
								<Link href="/search-by-code" clickEventName={clickEventName}>
									Search by product code
								</Link>
							</li>
							<li>
								<Link href={ROUTES.HELP.CONTACT_US} clickEventName={clickEventName}>
									Contact Us
								</Link>
							</li>
							<li>
								<Link href={ROUTES.HELP.FAQS} clickEventName={clickEventName}>
									FAQs
								</Link>
							</li>
							<li>
								<Link
									href={ROUTES.HELP.TERMS_AND_CONDITIONS}
									clickEventName={clickEventName}
								>
									Terms and Conditions
								</Link>
							</li>
							<li>
								<Link
									href={ROUTES.HELP.PAYMENT_METHOD}
									clickEventName={clickEventName}
								>
									Payment Method
								</Link>
							</li>
							<li>
								<Link
									href={ROUTES.HELP.PRIVACY_POLICY}
									clickEventName={clickEventName}
								>
									Privacy Policy
								</Link>
							</li>
							<li>
								<Link
									href={ROUTES.HELP.TERMS_OF_SALE}
									clickEventName={clickEventName}
								>
									Terms of Sale
								</Link>
							</li>
						</ul>
					</div>
				</div>
			</MaxWidthContainer>
		</footer>
	);
};

export default Footer;

import React from 'react';
import IconBase from './base';

interface IPaymentProps {
	className?: string;
	width?: number;
	height?: number;
}

const PaymentIcon: React.FC<IPaymentProps> = ({ className = '', width = 47.4, height = 39 }) => {
	return (
		<span className={`text-black ${className}`}>
			<IconBase width={`${width}px`} height={`${height}px`}>
				<path
					d="M31.983 19.038a10.263 10.263 0 11-5.021 5.63"
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
				/>
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					strokeLinecap="round"
					d="M41.477 18.965l-2.807-8.264-9.707-10.2H.501"
				/>
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					strokeLinecap="round"
					d="M.501 11.686h4.936l5.2 4.668a11.05 11.05 0 006.7 2.812 11.56 11.56 0 012.788.521 11.586 11.586 0 014.053 2.269l1.235 1.623a3.867 3.867 0 001.477 1.182l4.871 2.227c2.016.628 4.066-1.256 4.2-2.934.242-1.381-.456-2.757-1.422-2.98l-5.606-4.223-2.269-4.014h4.278l2.962 5.1"
				/>
			</IconBase>
		</span>
	);
};

export default PaymentIcon;

import { LOCALE, SITE_IDS } from '@b2b-frontend/constants';

export const getSiteId = (isNZ: boolean) => {
	if (isNZ) {
		return SITE_IDS.PROSHOP;
	}

	return SITE_IDS.SHOP;
};

export const useSiteId = () => {
	return getSiteId(useLocale() === LOCALE.NZ);
};

export const useLocale = (): LOCALE => {
	return process.env.NEXT_PUBLIC_LOCALE as LOCALE;
};

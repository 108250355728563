import React from 'react';
import IconBase from './base';

interface IRepeatIconProps {
	className?: string;
	width?: number;
}

const RepeatIcon: React.FC<IRepeatIconProps> = ({ className = '', width = 64.929 }) => {
	const aspectRatio = 0.56;
	const height = width * aspectRatio;

	return (
		<span className={`text-black ${className}`}>
			<IconBase width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
				<path
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeMiterlimit="10"
					d="M13.883 16.93h-7.6a5.786 5.786 0 00-5.786 5.786v7.207a5.786 5.786 0 005.786 5.786h9.547M30.773 5.98H14.605l2.2 11.19a91.961 91.961 0 011.718 17.691 1.241 1.241 0 001.242 1.242h6.249M.696 23.972h14.356M18.907 29.645h6.366M37.274.5h4.499"
				/>
				<path
					fill="none"
					stroke="currentColor"
					strokeLinecap="round"
					strokeMiterlimit="10"
					d="M42.902 13.599a16.732 16.732 0 00-1.186-2.091l-3.165-4.749h-5.167l-3.166 4.749a16.64 16.64 0 00-2.766 10.208l.718 12.179a2.341 2.341 0 002.338 2.2h5.224M36.204 36.099h5.224a2.34 2.34 0 001.893-.961M33.167.5h5.281v6.064h-5.281z"
				/>
				<g transform="translate(41.215 12.858)">
					<path
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeMiterlimit="10"
						d="M15.189 11.701l1.8-1.418 1.541 1.959M16.857 11.606A5.457 5.457 0 1111.4 6.149"
					/>
					<circle
						fill="none"
						stroke="currentColor"
						strokeLinecap="round"
						strokeMiterlimit="10"
						cx="11.607"
						cy="11.607"
						r="11.607"
					/>
				</g>
			</IconBase>
		</span>
	);
};

export default RepeatIcon;

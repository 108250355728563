import { BROWSER_STORAGE_KEYS } from '@b2b-frontend/constants';

export const getStorageItem = <R = string>(
	key: string,
	defaultValue?: R,
	engine: Storage = localStorage,
): R => {
	if (typeof window === 'undefined') {
		return defaultValue as R;
	}

	const valueFromStorage = (engine || localStorage).getItem(key);

	if (valueFromStorage === null) {
		return defaultValue as R;
	}

	return valueFromStorage as unknown as R;
};

export const setStorageItem = (
	key: BROWSER_STORAGE_KEYS | string,
	newValue: any,
	engine: Storage = localStorage,
): void => {
	if (typeof window === 'undefined') {
		return;
	}

	(engine || localStorage).setItem(key, newValue);
};

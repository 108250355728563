import React from 'react';
import IconBase from './base';

interface ILogoutIconProps {
	className?: string;
}

const LogoutIcon: React.FC<ILogoutIconProps> = ({ className = '' }) => {
	return (
		<IconBase
			width="18.532"
			height="25.936"
			viewBox="0 0 18.532 25.936"
			className={`${className}`}
		>
			<path
				d="M14.537 4.771V2.783A2.283 2.283 0 0012.254.5H2.782A2.283 2.283 0 00.5 2.783v20.371a2.282 2.282 0 002.282 2.282h9.472a2.283 2.283 0 002.283-2.282v-2.915"
				strokeMiterlimit="10"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
			/>
			<path
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeLinejoin="round"
				d="M13.376 7.871l4.656 4.656-4.656 4.656M18.032 12.528H4.207"
			/>
		</IconBase>
	);
};

export default LogoutIcon;

import React, { useEffect, useState } from 'react';
import dynamic from 'next/dynamic';
import { AccountSwitcherContext } from 'utils/context';
import { useRouter } from 'next/router';

const AccountSwitcher = dynamic(() => import('../components/AccountSwitcher'), { ssr: false });

const AccountSwitcherProvider = ({ children }) => {
	const [isAccountSwitcherOpen, setIsAccountSwitcherOpen] = useState(false);

	const openAccountSwitcher = () => setIsAccountSwitcherOpen(true);
	const closeAccountSwitcher = () => setIsAccountSwitcherOpen(false);

	return (
		<AccountSwitcherContext.Provider value={openAccountSwitcher}>
			<AccountSwitcher
				onCloseRequested={closeAccountSwitcher}
				openAccountSwitcher={openAccountSwitcher}
				isOpen={isAccountSwitcherOpen}
			/>
			{children}
		</AccountSwitcherContext.Provider>
	);
};

export default AccountSwitcherProvider;

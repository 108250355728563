import curry from 'lodash/curry';

export const getNewScreenSize = curry((windowWidth: number) => {
	if (windowWidth >= 1024) {
		return 'large';
	}

	if (windowWidth >= 768) {
		return 'medium';
	}

	if (windowWidth >= 640) {
		return 'small';
	}

	return 'xsmall';
});

const ROUTES = {
	API: {
		ROOT: '/api',
		AUTH: {
			ROOT: '/api/auth',
			LOGIN: '/api/auth/login',
			LOGOUT: '/api/auth/logout',
		},
	},
	HELP: {
		ROOT: '/help',
		FAQS: '/help/faqs',
		TERMS_AND_CONDITIONS: '/help/terms-and-conditions',
		PAYMENT_METHOD: '/help/payment-method',
		PRIVACY_POLICY: '/help/privacy-policy',
		TERMS_OF_SALE: '/help/terms-of-sale',
		CONTACT_US: '/help/contact-us',
	},
	LOGIN: '/login',
	MY_ACCOUNT: {
		ROOT: '/my-account',
		MY_PROFILE: '/my-account/my-profile',
		SALES_REP: '/my-account/sales-rep',
		DELIVERIES: '/my-account/deliveries',
		ORDERS: '/my-account/orders',
	},
	CHECKOUT: '/checkout',
	LEARN: {
		ROOT: '/learn',
		BRAND: '/learn/[brandName]',
	},
	WHATS_NEW: {
		ROOT: '/whats-new',
		BRAND: '/whats-new/[brandName]',
	},
	BRANDS: {
		ROOT: '/brands',
		BRAND: {
			ROOT: '/brands/[brandName]',
			CATEGORY: {
				ROOT: '/brands/[brandName]/[categoryName]',
				SUBCATEGORY: '/brands/[brandName]/[categoryName]/[subCategoryName]',
			},
		},
	},
};

export default ROUTES;

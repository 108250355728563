'use client';

import React, { useMemo } from 'react';
import clsx from 'clsx';
import NextLink, { LinkProps } from 'next/link';
import { useRouter } from 'next/router';
import match from 'url-pattern-match';
import { createLinkClickEvent } from '@b2b-frontend/utils/analytics/gtm';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';

export interface ILinkProps extends React.PropsWithChildren {
	href: string;
	className?: string;
	active?: string;
	title?: string;
	linkProps?: { [key: string]: any };
	clickEventName?: string; // for analytics
	nextLinkProps?: Omit<LinkProps, 'href'>;
}

const Link: React.FC<ILinkProps> = ({
	href,
	children,
	className = '',
	active = 'text-brand',
	title = '',
	linkProps = {},
	nextLinkProps = {},
	clickEventName,
}) => {
	const sendToAnalytics = useGTMDispatch();
	const { pathname, asPath } = useRouter();
	const matches = useMemo(() => {
		const { state } = match(pathname, href);
		const { state: asPathState } = match(asPath, href);

		return state || asPathState;
	}, [asPath, pathname, href]);

	const handleLinkClick = (e: React.MouseEvent) => {
		const target = e.target as HTMLElement;
		const theLinkEl = target.closest('a');
		sendToAnalytics(
			createLinkClickEvent({
				href: theLinkEl?.href ?? href,
				linkText: theLinkEl?.innerText ?? 'Unknown Link Text',
				eventName: clickEventName ?? 'clickLink',
			}),
		);
		linkProps?.onClick?.(e);
	};

	return (
		<NextLink
			href={href}
			{...nextLinkProps}
			className={clsx({
				[active]: matches,
				[className]: className,
			})}
			title={title}
			{...linkProps}
			onClick={handleLinkClick}
		>
			{children}
		</NextLink>
	);
};

export default Link;

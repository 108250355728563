import React from 'react';
import IconBase from './base';

interface IUploadProps {
	className?: string;
}

const UploadIcon: React.FC<IUploadProps> = ({ className = '' }) => {
	return (
		<IconBase
			width="11.061"
			height="10.246"
			viewBox="0 0 11.061 10.246"
			className={`${className}`}
		>
			<g fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round">
				<path d="M3.113 2.918 5.531.5l2.418 2.418" />
				<path d="M5.53.5v7.031" />
				<path d="M10.564 7.231v1.4a1.11 1.11 0 0 1-1.111 1.111H1.614A1.111 1.111 0 0 1 .503 8.631v-1.4" />
			</g>
		</IconBase>
	);
};

export default UploadIcon;

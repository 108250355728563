import React from 'react';
import IconBase from './base';

interface IComputerProps {
	className?: string;
	width?: number;
	height?: number;
}

const ComputerIcon: React.FC<IComputerProps> = ({ className = '', width = 63.7, height = 39 }) => {
	return (
		<span className={`text-black ${className}`}>
			<IconBase width={`${width}px`} height={`${height}px`}>
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					d="M8.547 32.975V5.27A4.772 4.772 0 0113.32.499h37.124a4.771 4.771 0 014.773 4.771v27.7"
				/>
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					d="M61.933 38.5h-60.1a1.33 1.33 0 01-1.33-1.33V32.9h62.76v4.27a1.33 1.33 0 01-1.33 1.33z"
				/>
				<path
					d="M26.43 7.035l13.077 12.027-5.484.349 2.333 5.317-3.637 1.6-2.335-5.317-3.954 3.782z"
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
				/>
			</IconBase>
		</span>
	);
};

export default ComputerIcon;

import React from 'react';
import IconBase from './base';

interface ISearchProps {
	className?: string;
}

const SearchIcon: React.FC<ISearchProps> = ({ className = '' }) => {
	return (
		<IconBase width="22.109" height="21.997" viewBox="0 0 22.109 21.997" className={className}>
			<g transform="translate(-1070.134 -344.75)">
				<circle
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					cx="8.857"
					cy="8.857"
					r="8.857"
					transform="translate(1070.634 345.25)"
				/>
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
					d="M1091.235 363.937l-4.48-4.48-1.914 1.914 4.48 4.479a1.353 1.353 0 001.914-1.913z"
				/>
			</g>
		</IconBase>
	);
};

export default SearchIcon;

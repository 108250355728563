export const HELP = {
	label: 'Let us help you',
	slug: 'help',
};

export const FAQS = {
	label: 'Frequently asked questions',
	slug: 'faqs',
};

export const TERMS = {
	label: 'Terms & conditions',
	slug: 'terms-and-conditions',
};

export const PAYMENT = {
	label: 'Payment method',
	slug: 'payment-method',
};

export const CONTACT_US = {
	label: 'Contact us',
	slug: 'contact-us',
};

export const PRIVACY = {
	label: 'Privacy policy',
	slug: 'privacy-policy',
};

export const TERMS_OF_SALE = {
	label: 'Terms of sale',
	slug: 'terms-of-sale',
};

export const MY_ACCOUNT = {
	label: 'My Account',
	slug: 'my-account',
};

export const MY_PROFILE = {
	label: 'My Profile',
	slug: 'my-profile',
};

export { BROWSER_STORAGE_KEYS } from '@b2b-frontend/constants';
export { default as ROUTES } from './routes';

import React from 'react';
import IconBase from './base';

interface IPhoneProps {
	className?: string;
}

const Phone: React.FC<IPhoneProps> = ({ className = '' }) => {
	return (
		<IconBase width="19.024" height="21.972" viewBox="0 0 19.024 21.972" className={className}>
			<path
				d="M18.051 16.769l-2.345-3.047a1.808 1.808 0 00-3.072.285 1.852 1.852 0 01-2.269.88 8.384 8.384 0 01-4.354-5.659A1.76 1.76 0 017.442 7.26a1.808 1.808 0 001.062-2.9L6.151 1.317a1.939 1.939 0 00-2.6-.339L1.484 2.569C-.596 4.256.761 9.622 4.697 14.737s8.762 7.9 10.939 6.222l2.067-1.591a1.939 1.939 0 00.348-2.599z"
				fill="none"
				stroke="currentColor"
				strokeMiterlimit="10"
			/>
		</IconBase>
	);
};

export default Phone;

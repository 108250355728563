import type { FC } from 'react';
import clsx from 'clsx';

const Spinner: FC<{
	className?: string;
	height?: `${number}px`;
}> = ({ className = '', height = '24px' }) => {
	return (
		<span
			className={clsx(
				className,
				'inline-block rounded-full aspect-square max-h-full animate-spin border-4 border-l-current border-r-current border-b-current border-t-current/40',
			)}
			style={{
				height,
			}}
		/>
	);
};

export default Spinner;

import React from 'react';
import IconBase from './base';

interface IPinIconProps {
	className?: string;
}

const PinIcon: React.FC<IPinIconProps> = ({ className = '' }) => {
	return (
		<IconBase
			className={`${className}`}
			width="29.758"
			height="39.641"
			viewBox="0 0 29.758 39.641"
		>
			<path
				d="M14.439,37.859l-.6-.809c-.681-.926-1.357-1.859-2.044-2.78Q7.619,28.681,3.438,23.1A13.622,13.622,0,0,1,.512,14.14,13.453,13.453,0,0,1,7.92,2.189,13.818,13.818,0,0,1,27.255,8.9a13.309,13.309,0,0,1-1.49,13.816C22.36,27.451,18.8,32.078,15.31,36.754Zm.047-27.991a4.637,4.637,0,1,0,4.628,4.664A4.652,4.652,0,0,0,14.485,9.868Z"
				transform="translate(0.364 0.363)"
				fill="none"
				stroke="currentColor"
				strokeLinecap="round"
				strokeWidth="1"
			/>
		</IconBase>
	);
};

export default PinIcon;

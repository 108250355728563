import React from 'react';
import IconBase from './base';

interface ISunIconProps {
	className?: string;
	width?: number;
}

const SunIcon: React.FC<ISunIconProps> = ({ className = '', width = 44.482 }) => {
	const aspectRatio = 1;
	const height = width * aspectRatio;

	return (
		<span className={`text-black ${className}`}>
			<IconBase width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
				<g transform="translate(11.501 11.5)">
					<path
						fill="none"
						stroke="currentColor"
						strokeMiterlimit={10}
						strokeLinecap="round"
						d="M7.623 10.741A10.733 10.733 0 0114.551.71a10.741 10.741 0 100 20.063 10.735 10.735 0 01-6.928-10.032z"
					/>
					<circle
						fill="none"
						stroke="currentColor"
						strokeMiterlimit={10}
						cx="10.741"
						cy="10.741"
						r="10.741"
					/>
				</g>
				<path
					fill="none"
					stroke="currentColor"
					strokeMiterlimit={10}
					strokeLinecap="round"
					d="M.5 22.241h6.41M37.572 22.241h6.41M22.241.5v6.41M22.241 37.572v6.41"
				/>
				<g>
					<path
						fill="none"
						stroke="currentColor"
						strokeMiterlimit={10}
						strokeLinecap="round"
						d="M6.868 37.614l4.532-4.532M33.082 11.4l4.532-4.532"
					/>
				</g>
				<g>
					<path
						fill="none"
						stroke="currentColor"
						strokeMiterlimit={10}
						strokeLinecap="round"
						d="M6.868 6.868L11.4 11.4M33.082 33.082l4.532 4.532"
					/>
				</g>
			</IconBase>
		</span>
	);
};

export default SunIcon;

import { createContext, useContext } from 'react';
import type { UserProfile } from '@auth0/nextjs-auth0/client';

interface IUserContext {
	user: UserProfile;
	isInitialised: boolean;
	isLoggedIn: boolean;
	isLoading: boolean;
}

export const UserContext = createContext<IUserContext | null>(null);

const useUser = (): IUserContext => {
	const c = useContext(UserContext);

	if (!c) {
		throw new Error('useUser must be used within a UserContextProvider');
	}

	return c;
};

export default useUser;

import { UserProvider } from '@auth0/nextjs-auth0/client';
import { AlertContextProvider } from '@b2b-frontend/components/AlertContext';
import { API_ERROR_CODES } from '@b2b-frontend/types/api';
import fetcher from '@b2b-frontend/utils/fetcher';
import { setStorageItem } from '@b2b-frontend/utils/storage';
import { GTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { setUser } from '@sentry/nextjs';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { useEffect, useRef, useState } from 'react';
import useSWR, { SWRConfig } from 'swr';
import { useLocale } from '@b2b-frontend/utils/sites';
import { CUSTOM_KEYS } from '@b2b-frontend/utils/analytics/constants';
import { parseBasicAddress } from '@b2b-frontend/utils/customer';
import * as Sentry from '@sentry/nextjs';
import CONFIG from '../constants/config';
import { BROWSER_STORAGE_KEYS, LOCALE } from '@b2b-frontend/constants';
import AcceptTermsModal from 'components/AcceptTermsModal';
import Header from 'components/Header';
import Footer from 'components/Footer';

import AccountSwitcherProvider from 'utils/AccountSwitcherProvider';
import BasketProvider from 'utils/BasketProvider';
import ResizeProvider from 'utils/ResizeProvider';
import { UserContext } from 'utils/useUser';
// import { getCustomer, getUser } from 'utils/app';

import '../styles.css';
import { ViewModifierContext, defaultViewModifierValue } from 'utils/ViewModifierProvider';
import { Situation } from 'utils/SituationProvider';
import { KEYS } from 'utils/SituationProvider/constants';
import { SessionSync } from 'components/SessionSync';

export interface IAppPageProps {
	dataLayerSeed?: Record<string, unknown>; // from <Component>
	globalDataLayerSeed?: Record<string, unknown>; // from <App>
}

const App = ({ Component, pageProps }: { Component: any; pageProps: IAppPageProps }) => {
	const { pathname } = useRouter();
	const locale = useLocale();
	const [shouldShowTermsModal, setShouldShowTermsModal] = useState(false);

	// This is used to prevent any XHRs from being made while the user is being kicked to login
	// this can take a few seconds, so we don't want to make any XHRs during this time
	// and disable all of them with this flag
	const loginKickProgress = useRef(false);

	const {
		data: auth0User,
		error: auth0Error,
		isValidating: isLoading,
	} = useSWR<{
		email: string;
		name: string;
		sub: string;
	}>('/api/auth/me', fetcher, {
		shouldRetryOnError: false,
		revalidateOnFocus: false,
	});
	const isInitialised = !!auth0User || !!auth0Error;
	const isLoggedIn = isInitialised && !!auth0User;

	useEffect(() => {
		setShouldShowTermsModal(!pathname.includes('/help'));
	}, [pathname]);

	useEffect(() => {
		if (!auth0User) {
			return;
		}

		setStorageItem(BROWSER_STORAGE_KEYS.USER_ID, auth0User.sub);
		setUser({
			email: auth0User.email,
			name: auth0User.name,
		});
	}, [auth0User]);

	const dataLayerSeed: Record<string, any> = {
		...pageProps.globalDataLayerSeed,
		...pageProps.dataLayerSeed,
		country: locale.toUpperCase(),
	};

	if (process.env.NEXT_PUBLIC_MAINTENANCE_MODE === 'true') {
		return (
			<div className="fixed h-screen w-screen flex flex-col items-center justify-center">
				<h1 className="text-5xl mb-4">Site under maintenance</h1>
				<p className="text-md">
					This website is currently undergoing planned maintenance. Please check back
					soon.
				</p>
			</div>
		);
	}

	return (
		<GTMProvider
			state={{
				id: CONFIG.GTM_ID,
				dataLayer: dataLayerSeed, // initial dataLayer values
			}}
		>
			<Situation
				value={{
					[KEYS.PAGE_CATEGORY]: dataLayerSeed?.pageCategory,
				}}
			>
				<Head>
					<title>Welcome to {process.env.appTitle}</title>
				</Head>
				<SWRConfig
					value={{
						fetcher,
						revalidateOnFocus: false,
						shouldRetryOnError: false,
						// don't make any XHRs if the page is being unloaded currently for kick to login
						isPaused: () => loginKickProgress.current,
						onError: async (error, lookupKey, swrConfig) => {
							const errorCode = error?.info?.code;

							Sentry.captureMessage(`Error fetching resource "${lookupKey}"`, {
								extra: { error, swrConfig },
							});

							if (errorCode === API_ERROR_CODES.ERR_TOKEN_EXPIRED) {
								if (!loginKickProgress.current) {
									loginKickProgress.current = true;
									Sentry.captureMessage('Token expired, kicking user to login');
									window.location.assign('/api/auth/login');
								}
							}
						},
					}}
				>
					<ViewModifierContext.Provider value={defaultViewModifierValue}>
						<UserProvider>
							<UserContext.Provider
								value={{
									// @ts-ignore
									user: auth0User,
									isInitialised,
									isLoading,
									isLoggedIn,
								}}
							>
								<BasketProvider>
									<ResizeProvider>
										<AccountSwitcherProvider>
											<AlertContextProvider>
												<SessionSync />
												<div className="flex flex-col items-center justify-center min-h-screen bg-white selection:bg-brand selection:text-black">
													<Header />
													<main className="flex-1 w-full flex flex-col justify-items-stretch">
														<Component {...pageProps} />
													</main>
													<Footer />
													{shouldShowTermsModal && <AcceptTermsModal />}
												</div>
											</AlertContextProvider>
										</AccountSwitcherProvider>
									</ResizeProvider>
								</BasketProvider>
							</UserContext.Provider>
						</UserProvider>
					</ViewModifierContext.Provider>
				</SWRConfig>
			</Situation>
		</GTMProvider>
	);
};

/**
 * Provides a common place to add global dataLayer attributes to all pages
 *
 * Note: we have to use `getInitialProps` here instead of `getServerSideProps` because
 * `getServerSideProps` is not supported on `App` components, and the alternative would be to
 * add this to every page, which is not DRY.
 *
 * Note: this function is not allowed to be run on the client (only on the server).
 * This is because the `getUser` function uses the server-only Auth0 custom instance. If
 * this module is run on the client, it will fail to initialise due to not having the
 * server-side-only `secret` env var available.
 */
App.getInitialProps = async ({ Component, ctx }: any) => {
	const isBrowser = typeof window !== 'undefined';

	if (isBrowser) {
		throw new Error(
			'App.getInitialProps must be run on the server only, and this was a client-side invocation',
		);
	}

	const pageProps = Component.getInitialProps ? await Component.getInitialProps(ctx) : {};
	// dynamically import this module so that the auth0 dependency is not bundled in the client
	const { getUser, getCustomer } = await import('utils/app');

	const user = await getUser(ctx);
	const customer = await getCustomer(ctx);

	let customerAddress: { city?: string; postcode?: string } = {};

	if (customer) {
		customerAddress = parseBasicAddress(customer?.address);
	}

	/**
	 * @see https://kollwitzowen.atlassian.net/wiki/spaces/B2B/pages/106233890/2023+-+DataLayer+Enhancement#Variable-References
	 */
	const dataLayerSeed = {
		uiLoggedStatus: user ? 'regular logged' : 'not logged',
		brand: 'B2B',
		language: 'en',
		siteTypeLevel: process.env.VERCEL_ENV === 'production' ? 'main' : 'testing',
		masterVersion: 'N/A',
		factory: 'LOCAL',
		websiteType: 'NON-DTC',
		[CUSTOM_KEYS.PRODUCT_VIRTUAL_TRY_ON]: 'not present',
		uiPostalCode: customerAddress?.postcode,
		uiCity: customerAddress?.city?.toLowerCase(),
		uiUser: user?.sub, // chosen to be not P.I.I.
	};

	const result = {
		pageProps: {
			...pageProps,
			globalDataLayerSeed: {
				// any global attributes that should be available on all pages (from App)
				...dataLayerSeed,
				// anything that was added to the pageProps.dataLayerSeed per-page
				...pageProps?.dataLayerSeed,
			},
		},
	};

	return result;
};

export default App;

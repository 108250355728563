import { IBasketProduct, ICheckout, IProduct } from '@b2b-frontend/types/index';
import { CUSTOM_KEYS } from './constants';
import { calculateShippingCost } from '../checkout';

const flattenProductsPerDivision = (checkout: ICheckout) => {
	return checkout.items
		.map(basketForDivision => basketForDivision.items)
		.flat()
		.map(item => {
			return {
				name: item.name,
				id: item.material,
				price: item.yourPrice,
				brand: item.signature,
				quantity: item.quantity,
				material: item.material,
				ean: item.barcode,
			};
		});
};

export const createProductClickEvent = ({
	clickAreaName: list,
	product,
}: {
	clickAreaName: string;
	product: IProduct;
}) => ({
	eventCategory: 'Ecommerce',
	eventAction: 'Product Click',
	event: 'productClick',
	eventLabel: `${product.name}::${product.material}`,
	event_name: 'select_item',
	ecommerce: {
		click: {
			actionField: { list },
			products: [
				{
					...product,
					name: product.name,
					material: product.material,
					price: product.yourPrice,
					brand: product.brandSlug,
					category: product.categorySlug,
				},
			],
		},
	},
});

/**
 * @see https://www.simoahava.com/analytics/enhanced-ecommerce-guide-for-google-tag-manager/#purchase
 */
export const createPurchaseEvent = ({
	checkout,
	locale,
}: {
	checkout: ICheckout;
	locale: 'AU' | 'NZ' | 'au' | 'nz';
}) => ({
	event: 'purchase',
	eventCategory: 'Ecommerce',
	eventAction: 'Purchase',
	eventLabel: 'Purchase',

	event_name: 'purchase',

	[CUSTOM_KEYS.PRODUCT_FINDER_REFERRER]: 'none',

	ecommerce: {
		purchase: {
			actionField: {
				id: checkout.id,
				affiliation: 'Online Store',
				revenue: checkout.total,
				tax: checkout.totalGst,
				shipping: calculateShippingCost(checkout).toString(),
				// coupon: ", // not derivable from checkout currently
			},
			products: checkout.items
				.map(basketForDivision => basketForDivision.items)
				.flat()
				.map(item => {
					return {
						...item,
						name: item.name,
						id: item.material,
						price: item.yourPrice,
						brand: item.signature,
						quantity: item.quantity,
					};
				}),
		},
	},
});

export const createViewProductEvent = ({
	product,
	currencyCode,
}: {
	product: IBasketProduct;
	currencyCode: 'AUD' | 'NZD';
}) => {
	return {
		event: 'uaevent',
		eventCategory: 'Ecommerce',
		eventAction: 'Product Detail',
		eventLabel: `${product.name}::${product.material}`,
		event_name: 'view_item',
		event_detail: 'product',
		ecommerce: {
			currencyCode,
			detail: {
				products: [
					{
						// @ts-ignore
						name: product.name,
						id: product.material,
						price: (product as any).yourPrice,
						// @ts-ignore
						quantity: product.quantity,
						...product,
					},
				],
			},
		},
	};
};

export const createAddToCartEvent = ({
	product,
	currencyCode,
}: {
	product: IBasketProduct;
	currencyCode: 'AUD' | 'NZD';
}) => {
	return {
		event: 'addToCart',
		eventCategory: 'Ecommerce',
		eventAction: 'Add to Cart',
		eventLabel: `${product.name?.trim()}::${product.material?.trim()}`,
		event_name: 'add_to_cart',
		ecommerce: {
			currencyCode,
			add: {
				products: [
					{
						...product,
						name: product.name,
						id: product.material,
						price: (product as any).price,
						quantity: product.quantity,
					},
				],
			},
		},
	};
};

export const createLinkClickEvent = ({
	href,
	linkText,
	eventName,
}: {
	href: string;
	linkText: string;
	eventName: string;
}) => {
	const { origin } = window.location;
	const isExternal = !href.startsWith(origin);

	return {
		event: eventName,
		linkText,
		linkUrl: href,
		type: isExternal ? 'external' : 'internal',
	};
};

export const createViewCartEvent = ({
	checkout,
	currencyCode,
}: {
	checkout: ICheckout;
	currencyCode: 'AUD' | 'NZD';
}) => {
	return {
		event: 'checkout',
		eventCategory: 'Ecommerce',
		eventAction: 'Checkout',
		eventLabel: 'Checkout',
		event_name: 'view_cart',
		ecommerce: {
			currencyCode,
			checkout: {
				products: flattenProductsPerDivision(checkout),
			},
		},
	};
};

export const createCheckoutSuccessEvent = () => {
	return {
		event: 'checkout',
		eventCategory: 'Ecommerce',
		eventAction: 'Checkout',
		eventLabel: 'Checkout',
		event_name: 'success',
		ecommerce: {
			checkout: {
				actionField: { step: 2 },
			},
		},
	};
};

export const createProductImpressionEvent = ({
	product,
	currencyCode,
	positionInList,
	pageCategory,
}: {
	product: IProduct;
	currencyCode: 'AUD' | 'NZD';
	positionInList?: number;
	pageCategory: string;
}) => {
	return {
		event: 'nievent',
		eventCategory: 'Ecommerce',
		eventAction: 'Product Impressions',
		eventLabel: pageCategory,
		event_name: 'view_item_list',
		ecommerce: {
			currencyCode,
			impressions: [
				{
					...product,
					name: product.name,
					id: product.material,
					price: (product as any).price,
					brand: product.brand,
					category: product.categorySlug,
					position: positionInList,
					variant: product.volume,
				},
			],
		},
	};
};

export const createFileDownloadEvent = ({
	fileName,
	fileType,
}: {
	fileName: string;
	fileType: string;
}) => {
	return {
		event: 'uaevent',
		eventCategory: 'download',
		eventAction: fileType,
		eventLabel: fileName,
		event_name: 'download',
		file_type: fileType,
		file_name: fileName,
	};
};

export const createHeaderMenuClickEvent = ({
	label,
	clickArea,
}: {
	label: string;
	clickArea: string;
}) => {
	return {
		event: 'uaevent',
		eventCategory: 'main menu navigation',
		eventAction: 'select::header',
		eventLabel: label,
		event_name: 'menu_click',
		click_area: clickArea,
	};
};

export const createCheckoutStartEvent = ({
	checkout,
	currencyCode,
}: {
	checkout: ICheckout;
	currencyCode: string;
}) => {
	return {
		event: 'eeCheckout',
		event_name: 'begin_checkout​',
		event_step: 1,
		eeAction: 'eeCheckout',
		pageType: 'Checkout',
		checkoutStep: 1,
		currencyCode,
		ecommerce: {
			checkout: {
				products: flattenProductsPerDivision(checkout),
			},
		},
	};
};

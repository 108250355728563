import React from 'react';

interface IIconBaseProps extends React.PropsWithChildren {
	width?: string | number;
	height?: string | number;
	viewBox?: string;
	className?: string;
}

const IconBase: React.FC<IIconBaseProps> = ({ height, width, children, ...otherProps }) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			width={`${width}`}
			height={`${height}`}
			{...otherProps}
		>
			{children}
		</svg>
	);
};

export default IconBase;

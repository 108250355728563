import { ErrorComponent } from 'next/dist/client/components/error-boundary';
import * as React from 'react';

export const ErrorHandler: ErrorComponent = ({ error, reset }) => {
	return (
		<div className="flex flex-col items-center justify-center h-screen">
			<h1 className="text-3xl font-bold text-center">An error occurred</h1>
			<p className="text-center">{error.message}</p>
			<button className="mt-4" onClick={() => reset()}>
				Reload
			</button>
		</div>
	);
};

import React from 'react';
import IconBase from './base';

interface IEyeIconProps {
	className?: string;
	width?: number;
}

const EyeIcon: React.FC<IEyeIconProps> = ({ className = '' }) => {
	return (
		<span className={`text-black ${className}`}>
			<IconBase width="53.345" height="25" viewBox="0 0 53.345 25">
				<defs>
					<clipPath id="a">
						<path fill="none" stroke="currentColor" d="M0 0h53.345v25H0z" />
					</clipPath>
				</defs>
				<g>
					<g clipPath="url(#a)" fill="none" stroke="currentColor">
						<path d="M1.08 12.5c7.491 7.448 16.242 11.737 25.591 11.737s18.1-4.289 25.591-11.738C44.771 5.051 36.024.762 26.671.762S8.571 5.051 1.08 12.5Z" />
						<circle
							cx="11.468"
							cy="11.468"
							r="11.468"
							transform="translate(15.204 1.032)"
						/>
						<circle
							cx="3.528"
							cy="3.528"
							r="3.528"
							transform="translate(23.145 8.973)"
						/>
					</g>
				</g>
			</IconBase>
		</span>
	);
};

export default EyeIcon;

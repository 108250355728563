import React, { useCallback, useMemo, useState } from 'react';
import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import useSWR from 'swr';
import { IBasket } from '@b2b-frontend/types/index';
import { parseInt } from 'lodash';
import { createAddToCartEvent } from '@b2b-frontend/utils/analytics/gtm';
import { useLocale } from '@b2b-frontend/utils/sites';
import { LOCALE } from '@b2b-frontend/constants';
import { addToBasket, updateQuantityInCart } from '../utils/basket';
import { BasketContext, BasketItemStatuses } from 'utils/useBasket';
import { getProductMapFromBasket } from 'utils/basket';

const BasketProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
	const sendDataToGTM = useGTMDispatch();
	const [itemState, setItemState] = useState<Record<string, BasketItemStatuses>>({});
	const { data, mutate, isValidating } = useSWR<IBasket>('/api/basket');
	const [basketError, setBasketError] = useState<null | string>(null);
	const locale = useLocale();

	const itemMap = useMemo(() => {
		if (!data) {
			return {};
		}

		return data.items.reduce(getProductMapFromBasket, {});
	}, [data]);

	const addItemToBasket = useCallback(
		async ({
			material,
			quantity,
			...otherProductAttributes
		}: {
			material: string;
			quantity: string;
			[key: string]: any;
		}) => {
			setBasketError(null);
			setItemState(state => ({
				...state,
				[material]: 'adding',
			}));

			const newQuantity = parseInt(quantity, 10);

			try {
				const response = await addToBasket(material, newQuantity);

				// @ts-ignore
				await mutate({
					...data,
					items: [...(data?.items ?? []), response.data],
				});
				sendDataToGTM(
					createAddToCartEvent({
						product: { ...otherProductAttributes, ...response.data },
						currencyCode: locale === LOCALE.NZ ? 'NZD' : 'AUD',
					}),
				);
			} catch (e) {
				setBasketError('Failed to add item to cart. Please try again');
			}

			setItemState(state => ({
				...state,
				[material]: null,
			}));
			await mutate();
		},
		[data, locale, mutate, sendDataToGTM],
	);

	const removeItemFromBasket = async (material: string) => {
		setBasketError(null);
		setItemState(state => ({
			...state,
			[material]: 'removing',
		}));

		await mutate(
			// @ts-ignore
			{
				...data,
				items: data?.items.filter(item => item.material !== material) ?? [],
			},
			false,
		);

		try {
			await removeItemFromBasket(material);
		} catch (e) {
			setBasketError('Failed to remove item from cart. Please try again');
		}

		setItemState(state => ({
			...state,
			[material]: null,
		}));
		await mutate();
	};

	const updateBasketQuantity = async ({ material, quantity }) => {
		setBasketError(null);
		setItemState(state => ({
			...state,
			[material]: 'adding',
		}));

		const newQuantity = typeof quantity === 'string' ? parseInt(quantity, 10) : quantity;

		try {
			await mutate(
				// @ts-ignore
				{
					...data,
					items:
						data?.items.map(item => {
							if (item.material !== material) {
								return item;
							}

							return {
								...item,
								quantity: newQuantity,
							};
						}) ?? [],
				},
				false,
			);
			await updateQuantityInCart(material, newQuantity);
		} catch (e) {
			setBasketError('Failed to update quantity. Please try again later');
		}

		setItemState(state => ({
			...state,
			[material]: null,
		}));
		await mutate();
	};

	return (
		<BasketContext.Provider
			value={{
				isBasketLoading: isValidating,
				error: basketError,
				itemState,
				setItemState,
				removeItemFromBasket,
				addToBasket: addItemToBasket,
				updateQuantity: updateBasketQuantity,
				fetchBasket: mutate,
				basket: data ?? null,
				itemMap,
			}}
		>
			{children}
		</BasketContext.Provider>
	);
};

export default BasketProvider;

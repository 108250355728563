import { useState } from 'react';
import { useCookies } from 'react-cookie';
import Modal from '@b2b-frontend/components/Modal';
import Checkbox from '@b2b-frontend/components/Checkbox';
import Button from '@b2b-frontend/components/Button';

const AcceptTermsModal = () => {
	const [cookie, setCookie] = useCookies(['accepted_terms']);
	const [isOpen, setIsOpen] = useState(true);
	const [hasAcceptedTerms, setHasAcceptedTerms] = useState(false);

	const handleContinue = () => {
		let dateNow = new Date();
		let dateOneYearFromNow = new Date(dateNow.setFullYear(dateNow.getFullYear() + 1));

		setCookie('accepted_terms', dateNow.toISOString(), {
			expires: dateOneYearFromNow,
		});

		setIsOpen(false);
	};

	if (!!cookie.accepted_terms) {
		return null;
	}

	const termsLink = (
		<a href="/help/terms-and-conditions" target="_blank" rel="noopener noreferrer">
			<span className="text-brand underline">Terms &amp; Conditions</span>
		</a>
	);

	const privacyLink = (
		<a href="/help/privacy-policy" target="_blank" rel="noopener noreferrer">
			<span className="text-brand underline">Privacy Policy</span>
		</a>
	);

	const checkBoxLabel = (
		<span>
			I have read and agree to the {termsLink} and {privacyLink}
		</span>
	);

	return (
		<Modal
			className="xs:h-[280px] w-[90%]"
			title="Conditions of entry"
			description=""
			isOpen={isOpen}
			manualClose
			onCloseRequested={() => {}}
			maxWidth={540}
		>
			<Checkbox
				checked={hasAcceptedTerms}
				id="accept_terms"
				name="accept_terms"
				label={checkBoxLabel}
				onChange={newVal => setHasAcceptedTerms(newVal)}
			/>
			<Button
				className="mt-6"
				type="button"
				variant="primary"
				disabled={!hasAcceptedTerms}
				onClick={handleContinue}
			>
				Continue To Site
			</Button>
		</Modal>
	);
};

export default AcceptTermsModal;

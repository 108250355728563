import { ICheckout } from '@b2b-frontend/types/index';

export const calculateShippingCost = (checkout?: ICheckout) => {
	if (!checkout) {
		return 0;
	}

	return (checkout?.items || []).length > 0
		? Object.entries(checkout?.min_amount || {}).reduce((acc, [_, value]) => {
				return acc + value.charge;
		  }, 0)
		: 0;
};

import React, { useEffect, useState } from 'react';
import { ResizeContext, ScreenSizes } from 'utils/context';
import { getNewScreenSize } from 'utils/reducer';
import useThrottle from 'utils/useThrottle';

const ResizeProvider = ({ children }) => {
	const initialScreenWidth = typeof window === 'undefined' ? 0 : window.innerWidth;
	const [screenSize, setScreenSize] = useState<ScreenSizes>(getNewScreenSize(initialScreenWidth));
	const [screenWidth, setScreenWidth] = useState(initialScreenWidth);

	const handleScreenSizeChanged: React.EventHandler<any> = event => {
		const newWidth = event.target.innerWidth;

		setScreenWidth(newWidth);
		setScreenSize(getNewScreenSize(newWidth));
	};

	const debouncedHandleScreenSizeChanged = useThrottle(handleScreenSizeChanged, 300);

	useEffect(() => {
		window.addEventListener('resize', debouncedHandleScreenSizeChanged);

		return () => {
			window.removeEventListener('resize', debouncedHandleScreenSizeChanged);
		};
	}, [debouncedHandleScreenSizeChanged]);

	return (
		<ResizeContext.Provider value={{ width: screenWidth, size: screenSize }}>
			{children}
		</ResizeContext.Provider>
	);
};

export default ResizeProvider;

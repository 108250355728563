import React from 'react';
import IconBase from './base';

interface IHeartIconProps {
	className?: string;
	width?: number;
}

const HeartIcon: React.FC<IHeartIconProps> = ({ className = '', width = 40.828 }) => {
	const aspectRatio = 0.89;
	const height = width * aspectRatio;

	return (
		<span className={`text-black ${className}`}>
			<IconBase width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
				<path
					d="M37.169 3.968A10.709 10.709 0 0029.204.5a10.02 10.02 0 00-6.259 2.161 12.771 12.771 0 00-2.529 2.642 12.827 12.827 0 00-2.532-2.642A10.012 10.012 0 0011.626.5a10.709 10.709 0 00-7.965 3.468 12.445 12.445 0 00-3.16 8.5c0 3.369 1.254 6.451 3.95 9.7a84.152 84.152 0 009.888 9.281 478.88 478.88 0 014.536 3.9 2.341 2.341 0 003.079 0 415.507 415.507 0 014.536-3.9 84.143 84.143 0 009.888-9.281c2.7-3.253 3.95-6.335 3.95-9.7a12.445 12.445 0 00-3.16-8.5zm0 0"
					fill="none"
					stroke="currentColor"
					strokeMiterlimit="10"
				/>
			</IconBase>
		</span>
	);
};

export default HeartIcon;

import React from 'react';
import IconBase from './base';

interface IShippingIconProps {
	className?: string;
	width?: number;
}

const ShippingIcon: React.FC<IShippingIconProps> = ({ className = '', width = 51.864 }) => {
	const aspectRatio = 0.77;
	const height = width * aspectRatio;

	return (
		<span className={`text-black ${className}`}>
			<IconBase width={width} height={height} viewBox={`0 0 ${width} ${height}`}>
				<g fill="none" strokeMiterlimit="10">
					<path d="M34.455 19.467l17.41-9.874v20.528l-17.41 9.874-17.41-9.874V9.593z" />
					<path
						d="M18.044 11.31v18.228l16.41 9.308 16.41-9.308V11.31l-16.41 9.308-16.41-9.308m-1-1.716l17.41 9.874 17.41-9.874V30.12l-17.41 9.874-17.41-9.874V9.593z"
						fill="#000"
					/>
				</g>
				<path
					d="M28.088 4.136l17.41 9.874.043 5.933-4.461 2.339v-6.063L23.67 6.345"
					stroke="#000"
					fill="none"
					strokeMiterlimit="10"
				/>
				<path
					fill="none"
					strokeMiterlimit="10"
					stroke="currentColor"
					strokeWidth="0.978px"
					d="M17.477 10.243L34.454.563l16.977 9.68M34.454 19.987v18.449"
				/>
				<path
					fill="none"
					strokeMiterlimit="10"
					stroke="currentColor"
					strokeLinecap="round"
					d="M17.303 13.866H5.177M17.303 19.944h-6.929M17.304 26.021H.5"
				/>
			</IconBase>
		</span>
	);
};

export default ShippingIcon;

import React, { useCallback, useMemo } from 'react';
import clsx from 'clsx';

interface IMaxWidthContainerProps extends React.PropsWithChildren {
	className?: string;
	size?: 'xs' | 'small' | 'regular' | 'medium' | 'large';
	noPadding?: boolean;
	padding?: string;
	id?: string;
}

const MaxWidthContainer: React.FC<IMaxWidthContainerProps> = ({
	children,
	className = '',
	size = 'regular',
	padding = 'px-6 2xl:px-0',
	noPadding = false,
	id,
}) => {
	const getSize = useCallback(() => {
		switch (size) {
			case 'large':
				return 1920;
			case 'medium':
				return 1640;
			case 'small':
				return 873;
			case 'xs':
				return 536;
			case 'regular':
			default:
				return 1364;
		}
	}, [size]);

	return (
		<div
			style={{ maxWidth: getSize() }}
			className={clsx('w-full mx-auto', {
				[className]: className,
				[padding]: !noPadding,
				'px-0': noPadding,
			})}
			id={id}
		>
			{children}
		</div>
	);
};

export default MaxWidthContainer;

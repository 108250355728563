import { BasketProductMap, IBasket, IBasketProduct, IBasketState } from '@b2b-frontend/types/index';
import axios, { AxiosRequestConfig } from 'axios';

export const getProductMapFromBasket = (
	basketMapSoFar: BasketProductMap,
	currentItem: IBasketProduct,
): BasketProductMap => {
	return {
		...basketMapSoFar,
		[currentItem.material]: currentItem,
	};
};

export const parseBasketResponse = (basket: IBasket | null): IBasketState | null => {
	if (!basket) {
		return null;
	}

	const { items = [] } = basket;

	return {
		...basket,
		items,
		itemCount: items.length,
		itemMap: items.reduce(getProductMapFromBasket, {}),
	};
};

export interface IUploadResponse {
	order_id: number;
	message: string;
	ean: string;
	material: string;
}

export const addToBasket = async (
	productId: string,
	quantity: number,
	config: AxiosRequestConfig = {
		timeout: 30000,
	},
): Promise<{ success: boolean; data: IBasketProduct }> => {
	const response = await axios.post(
		`/api/basket`,
		{
			material: productId,
			quantity: quantity,
		},
		config,
	);

	return response.data;
};

export const removeItemFromCart = async (
	material: string,
	config: AxiosRequestConfig = {
		timeout: 30000,
	},
): Promise<IBasket> => {
	const response = await axios.delete(`/api/basket/${material}`, config);

	return response.data.data;
};

export const updateQuantityInCart = async (
	material: string,
	quantity: number | string,
	config: AxiosRequestConfig = {
		timeout: 30000,
	},
): Promise<{ success: boolean; data: IBasket | null }> => {
	try {
		const response = await axios.put(
			`/api/basket/${material}`,
			{
				quantity: typeof quantity === 'string' ? quantity : `${quantity}`,
			},
			config,
		);

		return response.data;
	} catch (e) {
		return {
			success: false,
			data: null,
		};
	}
};
